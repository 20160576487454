<i18n>
[
    "nav__menuDropdown--accountOverview",
    "account__rentalHistory--title",
    "nav__menuDropdown--subscription",
    "account__billingReceiptsTab--title",
    "account__profileTab--title",
    "account__redeemTab--title",
    "nav_menuDropdown--giveGetOffer",
]
</i18n>

<template>
    <div class="c-header-links">
        <BaseLink
            v-for="({ name, displayName }, index) in links"
            :key="index"
            :to="{ name }"
            class="c-header-links__item o-text--body-copy u-text--black"
        >
            {{ displayName }}
            <span v-if="!isNonSubscriber && index === links.length-1" class="c-header-links__badge o-text--label">
                {{ $t('nav_menuDropdown--giveGetOffer') }}
            </span>
        </BaseLink>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { PROFILE_MODULE_NAME } from '~modules/profile/js/profile-store';

import { rentalRouteNames } from '~modules/core/js/router-constants';

export default {
    name: 'HeaderLinks',
    computed: {
        ...mapGetters(PROFILE_MODULE_NAME, [
            'isNonSubscriber',
        ]),
        links() {
            return [
                { displayName: this.$t('nav__menuDropdown--accountOverview'), name: rentalRouteNames.account.overview },
                ...(!this.isNonSubscriber ? [{
                    displayName: this.$t('account__rentalHistory--title'),
                    name: rentalRouteNames.account.history,
                }] : []),
                {
                    displayName: this.$t('nav__menuDropdown--subscription'),
                    name: rentalRouteNames.account.subscription,
                },
                ...(!this.isNonSubscriber ? [{
                    displayName: this.$t('account__billingReceiptsTab--title'),
                    name: rentalRouteNames.account.billing,
                }] : []),
                { displayName: this.$t('account__profileTab--title'), name: rentalRouteNames.account.profile },
                ...(!this.isNonSubscriber ? [{
                    displayName: this.$t('account__redeemTab--title'),
                    name: rentalRouteNames.account.redeem,
                }] : []),
            ];
        },
    },
};
</script>

<style lang="scss">
.c-header-links {
    &__item {
        width: 100%;
        text-align: left;
        outline-offset: -4px;
        cursor: pointer;
        padding: $nu-spacer-2;
        background-color: $nu-white;
        transition: all 0.2s ease-in-out;

        border-bottom: 1px solid $nu-gray--light;

        &:hover {
            background-color: $nu-secondary-20;
            padding-left: $nu-spacer-3;
        }
    }

    &__badge {
        padding: $nu-spacer-1 $nu-spacer-1pt5;
        margin-left: $nu-spacer-1pt5;
        border-radius: $nu-spacer-0pt5;
        background-color: $placeholder-background;
    }
}
</style>
