export const SITE_META = {
    defaultTitle: 'meta__rentDefault--title',
    fallbackTitle: 'meta__rentFallback--title',
    giftCardsDescription: 'meta__giftCards--description',
    giftCardsTitle: 'meta__giftCards--title',
    homepageDescription: 'meta__rentHomepage--description',
    homepageOGDescription: 'meta__rentHomepage--description',
    homepageTitle: 'meta__rentHomepage--title',
    homepageOGTitle: 'meta__rentHomepage--title',
    viewSimilarDescription: 'meta__viewSimilar--description',
    viewSimilarTitle: 'meta__viewSimilar--title',
    jsonLDName: 'Nuuly Rent',
    jsonLDUrlPath: '/rent',
};

export default SITE_META;
