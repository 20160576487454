<template>
    <UrbnModalLauncher
        title="Nuuly"
        :modalOptions="modalOptions"
        ariaLabelSlug="global__menu"
        :variant="'none'"
        type="button"
        class="c-navigation-launcher"
        @click="trackNavClick"
    >
        <BaseIcon
            icon="global--menu"
            size="16px"
            color="primary"
            class="c-navigation-launcher__icon"
        />
        <label id="c-navigation-launcher__menu-label" class="c-navigation-launcher__label">
            <slot></slot>
        </label>
    </UrbnModalLauncher>
</template>

<script>
import { mapActions } from 'vuex';

import { GLOBAL_EVENT } from '~coreModules/core/js/store';
import { NAVIGATION_CLICKED } from '~coreModules/core/js/global-event-constants';
import { TOP_NAV_EXPAND } from '~coreModules/analytics/js/analytics-constants';

import UrbnModalLauncher from '~coreModules/modals/components/UrbnModalLauncher.vue';

export default {
    name: 'NavigationMenuLauncher',
    components: {
        UrbnModalLauncher,
    },
    props: {
        modalOptions: {
            type: Object,
            required: true,
        },
    },
    methods: {
        ...mapActions({
            trackGlobalEvent: GLOBAL_EVENT,
        }),
        trackNavClick() {
            this.trackGlobalEvent({
                type: NAVIGATION_CLICKED,
                data: {
                    isGA4: true,
                    navAction: TOP_NAV_EXPAND,
                },
            });
        },
    },
};
</script>

<style lang="scss">
    .c-navigation-launcher {
        display: inline-flex;
        border: 0 none;
        align-items: center;
        padding: $nu-spacer-1;
        background-color: $transparent;
        transition: background-color 0.2s ease;

        &:hover,
        &:focus[data-focus-visible-added] {
            background-color: $nu-gray--light;
        }

        &,
        * {
            cursor: pointer;
        }

        &__icon svg {
            margin-right: 0;
        }

        &__label {
            display: inline-block;
            @include body-copy;
            color: $nu-primary;
        }
    }
</style>
