export default function (Component) {
  const _Component = Component
  _Component.__i18n = _Component.__i18n || []
  _Component.__i18n.push({
    "locale": "",
    "resource": {
      "en-US": {
        "nav__menuDropdown--accountOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Overview"])},
        "account__rentalHistory--title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Nuuly History"])},
        "nav__menuDropdown--subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription"])},
        "account__billingReceiptsTab--title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing + Receipts"])},
        "account__profileTab--title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile "])},
        "account__redeemTab--title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referrals + Rewards"])},
        "nav_menuDropdown--giveGetOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give $, Get $"])}
      }
    }
  })
}
