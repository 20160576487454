import {
    difference,
    isEmpty,
    filter,
    has,
    pick,
} from 'lodash-es';

import { CATALOG_QUERY_PAGE, CATALOG_QUERY_SORT } from '~coreModules/catalog/js/catalog-constants';
import { URBN_BRANDS } from '~coreModules/core/js/constants';
import { VIEW_PRODUCT_LIST } from '~coreModules/core/js/global-event-constants';
import { getFilterStringFromObject } from '~coreModules/catalog/js/catalog-utils';

import {
    CATALOG_QUERY_IN_STOCK,
    CATALOG_QUERY_SIZE,
    RESET_FILTER_QUERIES,
} from '~modules/catalog/js/catalog-constants';

/**
 * To find edited item which has been added or removed from the box
 * @param {Array} newBoxChoiceIds - the choice id in box after product removed
 * @param {Array} oldBoxChoiceIds - the choice id in box before product removed
 * @param {Array} choiceIdsInCatalogAndBox - the choice id in box
 * @returns {String} the choice id of the item that has been added or removed from the box
 */

export function getEditedChoiceIdInBox(newBoxChoiceIds, oldBoxChoiceIds, choiceIdsInCatalogAndBox) {
    let editedChoiceId = '';

    if (oldBoxChoiceIds.length === newBoxChoiceIds.length) {
        return editedChoiceId;
    }

    const choiceIdWasAddedToBox = newBoxChoiceIds.length > oldBoxChoiceIds.length;
    const arrayToCompare1 = choiceIdWasAddedToBox ? newBoxChoiceIds : oldBoxChoiceIds;
    const arrayToCompare2 = choiceIdWasAddedToBox ? oldBoxChoiceIds : newBoxChoiceIds;
    const choiceIdEditedInBox = difference(arrayToCompare1, arrayToCompare2)[0];
    const choiceIdEditedIsInCatalog = choiceIdsInCatalogAndBox.indexOf(choiceIdEditedInBox) !== -1;
    const editedChoiceShouldBeWatched = !choiceIdWasAddedToBox ? choiceIdEditedIsInCatalog : !choiceIdEditedIsInCatalog;

    if (editedChoiceShouldBeWatched) {
        editedChoiceId = choiceIdEditedInBox;
    }

    return editedChoiceId;
}

/**
 * Generates the query params that should be applied when a particular filter option is clicked
 * @param {Object} query - the currently applied query params
 * @param {String} filterValue - the value of the filterOption we are checking
 * @param {String} filterGroupName - the current filterGroup that is being processed
 * @returns {Object} The new query params that will be applied when the given filter is clicked
 */
export function getQueryParamsForFilterOption(query, filterValue, filterGroupName) {
    const mergedParams = { ...query };
    const activeFilterOption = mergedParams[filterGroupName];

    if (activeFilterOption) {
        const activeFilterOptionValues = activeFilterOption.split(',');
        const activeFilterOptionValuesLowercase = activeFilterOption.toLowerCase().split(',');
        const existingFilterValueIndex = activeFilterOptionValuesLowercase.indexOf(filterValue.toLowerCase());

        if (existingFilterValueIndex !== -1) {
            // This value is already in the querystring, remove it so it deselects when clicked
            activeFilterOptionValues.splice(existingFilterValueIndex, 1);
        } else {
            // The value is not in the querystring, add it so it becomes selected when clicked
            activeFilterOptionValues.push(filterValue);
        }

        // join the filterValues back together in a comma separated format EX: ?color=blue,green,red
        mergedParams[filterGroupName] = activeFilterOptionValues.join(',');

        if (!mergedParams[filterGroupName]) {
            // if the filterGroup value has become empty, remove it
            delete mergedParams[filterGroupName];
        }

    } else {
        // This is a filter group not currently present, add it
        mergedParams[filterGroupName] = filterValue;
    }

    /* remove page param for all new filter requests */
    delete mergedParams[CATALOG_QUERY_PAGE];

    return mergedParams;
}

/**
 * Determines whether or not a particular filterValue is currently applied or not
 * @param {Object} mergedQueryParams - the new query params that will be applied when the given filter is clicked
 * @param {String} filterValue - the value of the filterOption we are checking
 * @param {String} filterGroupName - the nane of the current filterGroup that is being processed
 * @returns {Boolean} Whether or not the current filter is selected
 */
export function filterIsSelected(mergedQueryParams, filterValue, filterGroupName) {
    const activeQueryFilterValue = mergedQueryParams[filterGroupName];

    return !activeQueryFilterValue || activeQueryFilterValue
        .toLowerCase().split(',').indexOf(filterValue.toLowerCase()) === -1;
}

/**
 * Shapes the r15 filterValues for a particular filterGroup
 * @param {Array} filterValues - the values in a particular filterGroup that can be filtered on
 * @param {String} filterGroupName - the nane of the current filterGroup that is being processed
 * @param {Object} query - the currently applied query params
 * @returns {Array} The formatted filterValues for a given filterGroup
 */
export function shapeFilterValues(filterValues, filterGroupName, query) {
    const shapedFilterOptions = filterValues.map((filterOption) => {
        const mergedQueryParams = getQueryParamsForFilterOption(query, filterOption, filterGroupName);

        return {
            displayName: filterOption,
            filterGroupName,
            value: filterOption,
            isSelected: filterIsSelected(mergedQueryParams, filterOption, filterGroupName),
            query: mergedQueryParams,
        };
    });

    return shapedFilterOptions;
}

/**
 * Generates a string that lists out all of the selected filters for a given filterGroup
 * @param {Object} filterGroup - object representing a group of filter options
 * @returns {String} list of the selected filters for a given filterGroup
 */
export function getSelectedFiltersForGroup(filterGroup) {
    const selectedFilters = filter(filterGroup.filterValues, filterOption => filterOption.isSelected === true);

    if (isEmpty(selectedFilters)) {
        return '';
    }

    return selectedFilters.map(filterOption => filterOption.displayName).join(', ');
}

/**
 * Shapes the r15 filterGroups object into a consumable format for the UI
 * @param {Array} filterGroups - list of filter types, that contain lists of filter options
 * @param {Object} query - the currently applied query params
 * @returns {Array} The formatted filterGroups
 */
export function shapeCatalogFilterGroups(filterGroups, query) {
    if (isEmpty(filterGroups)) {
        return [];
    }

    return filterGroups.map(filterGroup => ({
        ...filterGroup,
        filterValues: shapeFilterValues(filterGroup.filterValues, filterGroup.name, query),
    })).map(filterGroup => ({
        ...filterGroup,
        selectedFilters: getSelectedFiltersForGroup(filterGroup),
    }));
}

/**
 * Gets all of the filterOptions for a given filterGroup type
 * @param {String} filterGroupName - the name of the current filterGroup that is being processed
 * @param {Array} filterGroups - list of filter types, that contain lists of filter options
 * @returns {Array} the filterOptions belonging to a particular filterGroup
 */
export function getFilterOptionsForGroup(filterGroupName, filterGroups) {
    const selectedFilterGroup = filterGroups
        .find(filterGroup => filterGroup.name === filterGroupName);

    return selectedFilterGroup ? selectedFilterGroup.filterValues : [];
}

/**
 * Shapes the r15 size preferences object into a consumable format for the UI
 * @param {Object} preferredSizes - r15 response for user default size preferences
 * @returns {Array} The formatted selected size group
 */
export function shapeCatalogSizePreferences(preferredSizes) {
    const { petite, plus, regular } = preferredSizes;
    const allSizes = [...(petite || []), ...(regular || []), ...(plus || [])];

    return allSizes;
}

/**
 * Shapes the comma delimited size string into an array grouped by sizeType
 * @param {Object} selectedSizes - r15 response for user default size preferences
 * @returns {Array} The formatted selected size group
 */
export function shapeSelectedSizesFromString(selectedSizes = '') {
    const shapedSelectedSizes = {
        petite: [],
        regular: [],
        plus: [],
    };

    if (isEmpty(selectedSizes)) {
        return shapedSelectedSizes;
    }
    const sizes = selectedSizes.split(',');

    sizes.forEach((size) => {
        const cleanSize = size.toLowerCase();

        if (cleanSize.includes('p')) {
            shapedSelectedSizes.petite.push(size.trim());
        } else if (cleanSize.includes('w')) {
            shapedSelectedSizes.plus.push(size.trim());
        } else {
            shapedSelectedSizes.regular.push(size.trim());
        }
    });

    return shapedSelectedSizes;
}

/**
 * returns a query object that accounts for the users sizePreferences
 * @param {String} preferredSizeList - comma delimited string of size preferences
 * @param {Object} query - the currently applied query params
 * @returns {Object} The size preference adjusted query
 */
export function getSizePreferenceAdjustedQuery(preferredSizeList, query) {
    const newQuery = { ...query };

    if (!has(query, 'size') && preferredSizeList) {
        newQuery.size = preferredSizeList;
    } else if (has(query, 'size') && query.size === '') {
        newQuery.size = null;
    }

    return newQuery;
}

/**
* returns a query object that accounts for the users availability filter prefrences
* @param {Boolean} applyAvailabilityFilterPreference - availability filter preference
* @param {Object} query - the currently applied query params
* @returns {Object} The applied availability filter adjusted query
*/
export function getAvailabilityFilterAdjustedQuery(applyAvailabilityFilterPreference, query) {
    let newQuery = { ...query };
    const appliedFilter = query[CATALOG_QUERY_IN_STOCK];

    if (appliedFilter && typeof appliedFilter === 'string') {
        newQuery[CATALOG_QUERY_IN_STOCK] = appliedFilter === 'true';
    } else if (applyAvailabilityFilterPreference && !appliedFilter) {
        newQuery = {
            [CATALOG_QUERY_IN_STOCK]: applyAvailabilityFilterPreference,
            ...query,
        };
    }

    return newQuery;
}

/**
 * returns an object to pass to vue router that removes all catalog filters except for ones we want to retain
 * @param {Object} query - the currently applied query params
 * @param {Boolean} options.maintainAvailibilityFilter - whether or not to keep availablityFilter
 * @param {Boolean} options.maintainSizePreferences - whether or not to maintain users sizePreferences
 * @returns {Object} vue router link object
 */
export function getClearAllFiltersLinkObject(
    query,
    options = {
        maintainAvailibilityFilter: false,
        maintainSizePreferences: false,
    },
) {
    let clearFiltersQuery = pick(query, RESET_FILTER_QUERIES);
    const {
        maintainSizePreferences,
        maintainAvailibilityFilter,
    } = options;

    if (!maintainSizePreferences) {
        clearFiltersQuery = { ...clearFiltersQuery, ...{ [CATALOG_QUERY_SIZE]: '' } };
    }

    if (!maintainAvailibilityFilter) {
        clearFiltersQuery = { ...clearFiltersQuery, ...{ [CATALOG_QUERY_IN_STOCK]: false } };
    }

    return {
        query: clearFiltersQuery,
    };
}

/**
 * returns a user preference adjusted query to be used to hit the catalog service
 * @param {Object} query - the currently applied query params
 * @param {Object} catalogRequestSettings - settings/preferences to be used in the catalog request
 * @returns {Object} query adjusted for the users preferences
 */
export function getPreferenceAdjustedQuery(query, catalogRequestSettings) {
    const {
        preferredSizeList,
        applyAvailabilityFilterPreference,
        respectAvailabilityFilterPreference,
    } = catalogRequestSettings;
    let preferenceAdjustedQuery = { ...query };

    preferenceAdjustedQuery = getSizePreferenceAdjustedQuery(preferredSizeList, preferenceAdjustedQuery);

    if (respectAvailabilityFilterPreference) {
        preferenceAdjustedQuery = getAvailabilityFilterAdjustedQuery(
            applyAvailabilityFilterPreference, preferenceAdjustedQuery);
    }

    return preferenceAdjustedQuery;
}

/**
 * Shapes the r15 sortOptions into a consistent format with thrift
 * @param {Array} sortOptions - the values used for sorting
 * @returns {Array} The formatted filterValues for a given filterGroup
 */
export function shapeCatalogSortOptions(sortOptions) {
    return (sortOptions || []).map(option => ({
        displayName: option.displayName,
        slug: option.name,
    }));
}

/**
 * Returns the product brand display name
 *
 * @param  {Array} vendorBrands - the brands associated with the product
 * @return {String} the brand name
 */
export function getCatalogProductBrand(vendorBrands) {
    if (!vendorBrands?.length) return '';
    if (vendorBrands.length > 1) {
        return vendorBrands.filter(brand => !URBN_BRANDS.includes(brand))[0];
    }

    return vendorBrands[0];
}

/**
 * Returns the product brand display name
 *
 * @param  {Object} options - Options object
 * @param  {Array} options.products - products in the list
 * @param  {String} options.catalogResponseId - catalog response id for the list response
 * @param  {Array} options.filters - filters applied to the catalog request for the list
 * @param  {String} options.traceId - trace id sent from Data Science
 * @param  {String} options.listName - name of list
 * @param  {String} options.listId - id of the list
 * @return {Object} Analytics Object to be sent to GA4
 */
export function getCatalogTrayAnalyticsData({ products, catalogResponseId, filters, traceId, listName, listId }) {
    const shapedProducts = (products || []).map(product => ({
        productListName: listName,
        productListId: listId,
        productName: product.displayName,
        productPrice: product.msrpValue,
        productBrand: getCatalogProductBrand(product.vendorBrands),
        productId: product.choiceId,
    }));

    return {
        type: VIEW_PRODUCT_LIST,
        data: {
            products: shapedProducts,
            productList: {
                sortMethod: filters[CATALOG_QUERY_SORT] || '',
                responseId: catalogResponseId,
                filterString: getFilterStringFromObject(filters),
            },
            traceId,
        },
    };
}
