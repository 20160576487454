// APP Specific Error Validation Localizations
export default function getValidationLocalizations(i18n) {
    return {
        fields: {
            braSize: {
                regex: i18n.global.t('validationError__braSize--regex'),
            },
            bustSize: {
                regex: i18n.global.t('validationError__bust--regex'),
            },
            pauseMonths: {
                required: i18n.global.t('validationError__pauseCycles--empty'),
            },
            listName: {
                required: i18n.global.t('validationError__sublistName--minCharacters'),
            },
            couponCode: {
                required: i18n.global.t('onboarding__inviteCode--noCodeEntered'),
            },
            giftCardCode: {
                required: i18n.global.t('giftCard__redeemCta--invalidCode'),
            },
        },
        names: {
            bodyShape: i18n.global.t('profile__bodyType--label'),
            bustSize: i18n.global.t('profile__bust--label'),
            heightFeet: i18n.global.t('profile__heightFeet--label'),
            heightInches: i18n.global.t('profile__heightInches--label'),
            pauseMonths: i18n.global.t('subscription__pauseCycles--label'),
            petitePreference: i18n.global.t('profile__petitePreferences--label'),
            sizePetite: i18n.global.t('profile__sizes--label'),
            sizePlus: i18n.global.t('profile__sizes--label'),
            sizeRegular: i18n.global.t('profile__sizes--label'),
            weightLbs: i18n.global.t('profile__weightLbs--label'),
        },
    };
}
