import { merge } from 'lodash-es';
import createProfileStore, { GET_PROFILE_DATA, SET_CORE_PROFILE_DATA } from '~coreModules/profile/js/profile-store';
import { formatAddress } from '~coreModules/profile/js/profile-utils';

import runtimeConfig from '~config/config';

import { getCurrentCoupons } from '~routes/account/js/subscriptions-api';

import {
    getCreditBalance,
    getRewardsHistory,
    updateShippingAddress,
    getPersonalReferralLink,
} from '~modules/profile/js/profile-api';
import {
    SUBSCRIPTION_STATUSES,
    HAS_SUBSCRIPTION_STATUSES,
    WAITLIST_SIGNUP_LOCATIONS,
    WAITLIST_ELIGIBLE_STATUSES,
} from '~modules/profile/js/profile-constants';
import { NUU_COOKIE_USER_IS_WAITLISTED } from '~modules/core/js/cookie-constants';
import { shapeCatalogSizePreferences } from '~modules/catalog/js/catalog-utils';

import { SET_IS_OVERDUE } from '~modules/core/js/store';
import { BOX_MODULE_NAME } from '~modules/box/js/box-store';
import { SITE_SETTINGS_MODULE_NAME } from '~modules/site-settings/js/site-settings-store';

import { getContentfulPageContentForSlug } from '~coreModules/contentful/js/contentful-client';
import { shapeContentfulContentPageResponse } from '~coreModules/contentful/js/contentful-utils';
import { CONTENT_TYPES, CONTENT_SLUGS } from '~modules/contentful/js/contentful-constants';
import { PROSPECT_BOX_SUBSCRIPTION_STATUSES, BOX_ORDER_STATUSES } from '~modules/box/js/box-constants';

export { PROFILE_MODULE_NAME } from '~coreModules/profile/js/profile-store';

export const SET_ALL_PROFILE_DATA = 'SET_ALL_PROFILE_DATA';
export const GET_PROFILE_DATA_RENT = 'GET_PROFILE_DATA_RENT';
export const SET_ADDITIONAL_APP_PROFILE_DATA = 'SET_ADDITIONAL_APP_PROFILE_DATA';
export const GET_CREDIT_BALANCE = 'GET_CREDIT_BALANCE';
export const SET_CREDIT_BALANCE = 'SET_CREDIT_BALANCE';
export const GET_REFERRAL_REWARDS = 'GET_REFERRAL_REWARDS';
export const SET_REFERRAL_REWARDS = 'SET_REFERRAL_REWARDS';
export const SET_REWARDS_HISTORY = 'SET_REWARDS_HISTORY';
export const GET_REWARDS_HISTORY = 'GET_REWARDS_HISTORY';
export const UPDATE_SHIPPING_ADDRESS = 'UPDATE_SHIPPING_ADDRESS';
export const SET_SHIPPING_ADDRESS = 'SET_SHIPPING_ADDRESS';
export const GET_REDEEM_CONTENT = 'GET_REDEEM_CONTENT';
export const SET_REDEEM_CONTENT = 'SET_REDEEM_CONTENT';
export const GET_REDEEM_EMPTY_CONTENT = 'GET_REDEEM_EMPTY_CONTENT';
export const SET_REDEEM_EMPTY_CONTENT = 'SET_REDEEM_EMPTY_CONTENT';
export const GET_APPLIED_COUPON = 'GET_APPLIED_COUPON';
export const SET_APPLIED_COUPON = 'SET_APPLIED_COUPON';
export const GET_WAITLIST_COOKIE = 'GET_WAITLIST_COOKIE';
export const SET_USER_IS_WAITLISTED = 'SET_USER_IS_WAITLISTED';
export const CHECK_FOR_WAITLIST_COOKIE = 'CHECK_FOR_WAITLIST_COOKIE';
export const GET_REFERRAL_LINK = 'GET_REFERRAL_LINK';
export const SET_REFERRAL_LINK = 'SET_REFERRAL_LINK';
export const SET_GIFT_CARD_APPLY_EXIT_ROUTE = 'SET_GIFT_CARD_APPLY_EXIT_ROUTE';

export default function createRentProfileStore() {
    return merge(createProfileStore(), {
        state: {
            birthday: {
                day: '',
                month: '',
                year: '',
            },
            braSize: {
                band: '',
                cup: '',
            },
            bodyType: '',
            closetId: '',
            displayOnReviews: {
                age: false,
                braSize: false,
                height: false,
                weight: false,
            },
            height: {
                ft: '',
                in: '',
            },
            pauseCyclesRequested: '',
            pauseCyclesRemaining: '',
            preferredSizes: {
                petite: [],
                plus: [],
                regular: [],
            },
            nextBillingCycle: '',
            shippingAddress: {
                firstName: '',
                lastName: '',
                line1: '',
                line2: '',
                city: '',
                state: '',
                zip: '',
                verificationStatus: '',
            },
            subscriptionStatus: '',
            boxReceiptPendingStatus: '',
            weight: '',
            plan: '',
            cardType: '',
            lastFour: '',
            rewardsHistory: [],
            redeemContentfulContent: {},
            redeemEmptyContentfulContent: {},
            appliedCoupon: {},
            signUpLocation: '',
            userHasWaitlistCookie: false,
            referralLink: {},
            creditBalance: 0,
            giftCardApplyExitRoute: '',
        },

        /* eslint-disable no-param-reassign */
        mutations: {
            [SET_ADDITIONAL_APP_PROFILE_DATA](state, profileData) {
                const {
                    closetIds,
                    preferences,
                    status,
                    subscription,
                    communication,
                } = profileData;
                const {
                    boxReceiptPendingStatus,
                    nextBillingCycle,
                    pauseCyclesRequested,
                    subscriptionStatus,
                    pauseCyclesRemaining,
                } = status;
                const {
                    shippingAddress,
                    plan,
                    cardType,
                    lastFour,
                } = subscription;
                const {
                    birthday,
                    displayOnReviews,
                    preferredSizes,
                    bodyType,
                    braSize,
                    customaryHeight,
                    customaryWeight,
                } = preferences;
                const { signUpLocation } = communication;
                const [closetId] = closetIds;

                Object.assign(state, {
                    birthday,
                    bodyType,
                    braSize,
                    displayOnReviews,
                    closetId,
                    height: customaryHeight,
                    nextBillingCycle,
                    boxReceiptPendingStatus,
                    pauseCyclesRequested,
                    pauseCyclesRemaining,
                    preferredSizes,
                    signUpLocation,
                    subscriptionStatus,
                    shippingAddress,
                    plan,
                    cardType,
                    lastFour,
                    weight: customaryWeight ? customaryWeight.lbs : '',
                });
            },
            [SET_CREDIT_BALANCE](state, creditBalance) {
                state.creditBalance = creditBalance;
            },
            [SET_REWARDS_HISTORY](state, rewardsHistory) {
                state.rewardsHistory = rewardsHistory;
            },
            [SET_SHIPPING_ADDRESS](state, shippingAddress) {
                state.shippingAddress = shippingAddress;
            },
            [SET_REDEEM_CONTENT](state, redeemContentfulContent) {
                state.redeemContentfulContent = redeemContentfulContent;
            },
            [SET_REDEEM_EMPTY_CONTENT](state, redeemEmptyContentfulContent) {
                state.redeemEmptyContentfulContent = redeemEmptyContentfulContent;
            },
            [SET_APPLIED_COUPON](state, appliedCoupon) {
                state.appliedCoupon = appliedCoupon;
            },
            [SET_USER_IS_WAITLISTED](state) {
                this.$cookies.set(NUU_COOKIE_USER_IS_WAITLISTED, true);
                state.userHasWaitlistCookie = true;
            },
            [SET_REFERRAL_LINK](state, referralLink) {
                state.referralLink = referralLink;
            },
            [SET_GIFT_CARD_APPLY_EXIT_ROUTE](state, exitRoute) {
                state.giftCardApplyExitRoute = exitRoute;
            },
        },

        /* eslint-enable no-param-reassign */
        actions: {
            [CHECK_FOR_WAITLIST_COOKIE]({ commit }) {
                if (this.$cookies.get(NUU_COOKIE_USER_IS_WAITLISTED)) {
                    commit(SET_USER_IS_WAITLISTED);
                }
            },
            [GET_PROFILE_DATA_RENT]({ commit, dispatch, rootGetters }) {
                const { loggedIn } = rootGetters;

                if (!loggedIn) {
                    return Promise.resolve();
                }

                return dispatch(GET_PROFILE_DATA)
                    .then((profileData) => {
                        commit(SET_ADDITIONAL_APP_PROFILE_DATA, profileData);

                        if (profileData.status &&
                            profileData.status.subscriptionStatus === SUBSCRIPTION_STATUSES.overdue) {
                            commit(SET_IS_OVERDUE, true, { root: true });
                        }
                    })
                    .then(() => dispatch(GET_REWARDS_HISTORY))
                    .catch((error) => {
                        this.$logger.debugError('Failed to fetch the user\'s profile: ', error);
                        return Promise.reject(error);
                    });
            },
            [SET_ALL_PROFILE_DATA]({ commit }, profileData) {
                commit(SET_CORE_PROFILE_DATA, profileData);
                commit(SET_ADDITIONAL_APP_PROFILE_DATA, profileData);
            },
            [GET_CREDIT_BALANCE]({ commit }) {
                return getCreditBalance(this.$r15Svc)
                    .then((res) => {
                        commit(SET_CREDIT_BALANCE, res?.creditBalance);
                    })
                    .catch((error) => {
                        this.$logger.debugError('Failed to fetch the user\'s credit balance: ', error);
                        return Promise.reject(error);
                    });
            },
            [GET_REWARDS_HISTORY]({ commit }) {
                return getRewardsHistory(this.$r15Svc)
                    .then((res) => {
                        commit(SET_REWARDS_HISTORY, res?.rewards);
                    })
                    .catch((error) => {
                        this.$logger.debugError('Failed to fetch the user\'s rewards history: ', error);
                        return Promise.reject(error);
                    });
            },
            [GET_REFERRAL_LINK]({ commit }) {
                return getPersonalReferralLink(this.$r15Svc)
                    .then((res) => {
                        commit(SET_REFERRAL_LINK, res);
                    })
                    .catch((error) => {
                        this.$logger.debugError('Failed to fetch the user\'s referral link: ', error);
                        return Promise.reject(error);
                    });
            },
            [UPDATE_SHIPPING_ADDRESS]({ commit }, { shippingAddress }) {
                return updateShippingAddress(this.$r15Svc, shippingAddress)
                    .then(() => {
                        commit(SET_SHIPPING_ADDRESS, shippingAddress);
                    })
                    .catch((error) => {
                        this.$logger.debugError('Failed to update user\'s shipping address: ', error);
                        return Promise.reject(error);
                    });
            },
            [GET_REDEEM_CONTENT]({ commit }) {
                return getContentfulPageContentForSlug({
                    contentfulSvc: this.$contentfulSvc,
                    contentType: CONTENT_TYPES.CONTENT_PAGE,
                    slug: CONTENT_SLUGS.REDEEM_CONTENT_SLUG,
                    isTimed: false,
                }).then((res) => {
                    const redeemContentfulContent = shapeContentfulContentPageResponse(res);
                    commit(SET_REDEEM_CONTENT, redeemContentfulContent);
                }).catch((error) => {
                    this.$logger.debugError('Failed to fetch redeem contentful content: ', error);
                });
            },
            [GET_REDEEM_EMPTY_CONTENT]({ commit }) {
                return getContentfulPageContentForSlug({
                    contentfulSvc: this.$contentfulSvc,
                    contentType: CONTENT_TYPES.CONTENT_PAGE,
                    slug: CONTENT_SLUGS.REDEEM_EMPTY_CONTENT_SLUG,
                    isTimed: false,
                }).then((res) => {
                    const redeemEmptyContentfulContent = shapeContentfulContentPageResponse(res);
                    commit(SET_REDEEM_EMPTY_CONTENT, redeemEmptyContentfulContent);
                }).catch((error) => {
                    this.$logger.debugError('Failed to fetch empty redeem contentful content: ', error);
                });
            },
            [GET_APPLIED_COUPON]({ commit }) {
                return getCurrentCoupons(this.$r15Svc)
                    .then(res => commit(SET_APPLIED_COUPON, res))
                    .catch((error) => {
                        this.$logger.debugError('Failed to fetch the user\'s applied coupon info: ', error);
                        return Promise.reject(error);
                    });
            },
        },

        getters: {
            isNonSubscriber(state) {
                const { nonSubscriber } = SUBSCRIPTION_STATUSES;
                return state.subscriptionStatus === nonSubscriber;
            },
            isSubscribed(state) {
                return state.subscriptionStatus === SUBSCRIPTION_STATUSES.subscriber;
            },
            isAnonymousOrNonSubscriber(state, getters, rootState, rootGetters) {
                const { loggedIn } = rootGetters;
                return state.subscriptionStatus === SUBSCRIPTION_STATUSES.nonSubscriber || !loggedIn;
            },
            isAnonymousEmailSubscriber(state, getters) {
                return state.email && getters.isAnonymousOrNonSubscriber;
            },
            isProspectiveUser(state) {
                // NU_FEATURE: PROSPECT_BOX
                if (!runtimeConfig.features.prospectBox) return false;

                return PROSPECT_BOX_SUBSCRIPTION_STATUSES.includes(state.subscriptionStatus);
            },
            isProspectiveUserBlockedFromSubscribing(state, getters, rootState, rootGetters) {
                const { isProspectiveUser } = getters;
                const blockNewSubscriptions = rootGetters[`${SITE_SETTINGS_MODULE_NAME}/blockNewSubscriptions`];

                return isProspectiveUser && blockNewSubscriptions;
            },
            isWaitlistActive(state, getters, rootState, rootGetters) {
                return rootGetters[`${SITE_SETTINGS_MODULE_NAME}/blockNewSubscriptions`];
            },
            isWaitlistActiveWhileAnonymous(state, getters) {
                const { isWaitlistActive } = getters;
                return isWaitlistActive && state.subscriptionStatus === '';
            },
            isWaitlistEligible(state, getters) {
                const { isWaitlistActive } = getters;
                return isWaitlistActive && WAITLIST_ELIGIBLE_STATUSES.includes(state.subscriptionStatus);
            },
            isWaitlistedUser(state, getters) {
                const { isWaitlistEligible } = getters;
                const isWaitListedSet =
                    WAITLIST_SIGNUP_LOCATIONS.includes(state.signUpLocation) ||
                    state.userHasWaitlistCookie;

                return isWaitlistEligible && isWaitListedSet;
            },
            /* active or already paused subscription (not pause requested) */
            isSubscriberOrPaused(state) {
                const { subscriber, paused } = SUBSCRIPTION_STATUSES;
                return state.subscriptionStatus === subscriber || state.subscriptionStatus === paused;
            },
            /* already cancelled or cancel requested subscription */
            isCancelledOrCancelRequested(state) {
                const { cancelled, cancelRequested } = SUBSCRIPTION_STATUSES;
                return state.subscriptionStatus === cancelled || state.subscriptionStatus === cancelRequested;
            },
            /* already paused or pause requested subscription */
            isPausedOrPauseRequested(state) {
                const { paused, pauseRequested } = SUBSCRIPTION_STATUSES;
                return state.subscriptionStatus === paused || state.subscriptionStatus === pauseRequested;
            },
            /* user is eligible to cancel their subscription */
            isCancelEligible(state, getters) {
                const { effectiveSubscriptionStatus } = getters;
                return [
                    SUBSCRIPTION_STATUSES.subscriber,
                    SUBSCRIPTION_STATUSES.paused,
                ].includes(effectiveSubscriptionStatus);
            },
            /* user is eligible to pause their subscription */
            isPauseEligible(state, getters) {
                const { effectiveSubscriptionStatus } = getters;
                return effectiveSubscriptionStatus === SUBSCRIPTION_STATUSES.subscriber;
            },
            isTerminated(state, getters) {
                const { effectiveSubscriptionStatus } = getters;
                return effectiveSubscriptionStatus === SUBSCRIPTION_STATUSES.terminated;
            },
            /* takes into account what status a subscription will be in once their box is returned */
            effectiveSubscriptionStatus(state) {
                return state.boxReceiptPendingStatus || state.subscriptionStatus;
            },
            /* user is either non-subscriber, or has been cancelled/terminated */
            userHasNoActiveSubscription(state, getters) {
                const { isNonSubscriber } = getters;

                return isNonSubscriber || state.subscriptionStatus === SUBSCRIPTION_STATUSES.cancelled ||
                    state.subscriptionStatus === SUBSCRIPTION_STATUSES.terminated;
            },
            /* user is a subscriber, but may be paused, or requesting to pause/cancel */
            userHasSubscription(state) {
                return HAS_SUBSCRIPTION_STATUSES.includes(state.subscriptionStatus);
            },
            userHasPaymentMethod(state) {
                return !!(state.cardType && state.lastFour);
            },
            formattedShippingAddress(state) {
                return formatAddress(state.shippingAddress);
            },
            /* comma delimited string of size preferences, to be used in queries */
            preferredSizeList(state) {
                return shapeCatalogSizePreferences(state.preferredSizes || {}).sort().join(', ');
            },
            userCanExtendPause(state, getters) {
                const { effectiveSubscriptionStatus } = getters;
                const { pauseCyclesRemaining } = state;

                return effectiveSubscriptionStatus === SUBSCRIPTION_STATUSES.paused && pauseCyclesRemaining === 0;
            },
            isSubscribedWithOpenBox(state, getters, rootState) {
                const { isSubscribed } = getters;
                const { boxOrderStatus } = rootState[BOX_MODULE_NAME];

                return isSubscribed && boxOrderStatus === BOX_ORDER_STATUSES.OPEN;
            },
        },
    });
}
