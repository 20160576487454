import { get, merge } from 'lodash-es';

import createSiteSettings from '~coreModules/site-settings/js/site-settings-store';
import { MAX_AGE_6_MONTHS } from '~coreModules/core/js/cookie-constants';
import { generatePreferencesFromCookies } from '~coreModules/site-settings/js/site-settings-utils';

import {
    CATALOG_QUERY_WHITELIST,
    DEFAULT_CATALOG_GRID_LAYOUT,
    DEFAULT_CATALOG_AVAILABILITY_FILTER,
} from '~modules/catalog/js/catalog-constants';
import {
    NUU_COOKIE_CATALOG_GRID_LAYOUT,
    NUU_COOKIE_CATALOG_AVAILABILITY_FILTER,
    USER_PREFERENCE_COOKIES,
    USER_PREFERENCE_KEY_GRID_LAYOUT,
    USER_PREFERENCE_KEY_AVAILABILITY_FILTER,
} from '~modules/site-settings/js/site-settings-constants';

export { SITE_SETTINGS_MODULE_NAME } from '~coreModules/site-settings/js/site-settings-store';

export const SET_USER_PREFERENCES_FROM_COOKIES = 'SET_USER_PREFERENCES_FROM_COOKIES';
export const SET_GRID_LAYOUT_PREFERENCE = 'SET_GRID_LAYOUT_PREFERENCE';
export const SET_AVAILABILITY_FILTER_PREFERENCE = 'SET_AVAILABILITY_FILTER_PREFERENCE';
export const SET_INVENTORY_DC_POOL_OVERRIDE = 'SET_INVENTORY_DC_POOL_OVERRIDE';

export const UPDATE_GRID_LAYOUT_PREFERENCE = 'UPDATE_GRID_LAYOUT_PREFERENCE';
export const UPDATE_AVAILABILITY_FILTER_PREFERENCE = 'UPDATE_AVAILABILITY_FILTER_PREFERENCE';

export default function createRentSiteSettings(logger, config) {
    return merge(createSiteSettings(logger, config), {

        state: {
            preferences: {
                [USER_PREFERENCE_KEY_GRID_LAYOUT]: DEFAULT_CATALOG_GRID_LAYOUT,
                [USER_PREFERENCE_KEY_AVAILABILITY_FILTER]: DEFAULT_CATALOG_AVAILABILITY_FILTER,
            },
            inventoryDCPoolOverride: null,
        },

        /* eslint-enable no-param-reassign */
        /* eslint-disable no-undef */
        actions: {
            [UPDATE_GRID_LAYOUT_PREFERENCE]({ commit }, gridLayout) {
                this.$cookies.set(
                    NUU_COOKIE_CATALOG_GRID_LAYOUT,
                    gridLayout,
                    { maxAge: MAX_AGE_6_MONTHS },
                );
                return commit(SET_GRID_LAYOUT_PREFERENCE, gridLayout);
            },
            [UPDATE_AVAILABILITY_FILTER_PREFERENCE]({ commit }, availability) {
                this.$cookies.set(
                    NUU_COOKIE_CATALOG_AVAILABILITY_FILTER,
                    availability,
                    { maxAge: MAX_AGE_6_MONTHS },
                );
                return commit(SET_AVAILABILITY_FILTER_PREFERENCE, availability);
            },
        },

        /* eslint-disable no-param-reassign */
        mutations: {
            [SET_USER_PREFERENCES_FROM_COOKIES](state, cookies) {
                const cookieSetUserPreferences = generatePreferencesFromCookies(cookies, USER_PREFERENCE_COOKIES);

                state.preferences = {
                    ...state.preferences,
                    ...cookieSetUserPreferences,
                };
            },
            [SET_GRID_LAYOUT_PREFERENCE](state, gridLayout) {
                state.preferences[USER_PREFERENCE_KEY_GRID_LAYOUT] = gridLayout;
            },
            [SET_AVAILABILITY_FILTER_PREFERENCE](state, availability) {
                const preference = availability === 'true';

                state.preferences[USER_PREFERENCE_KEY_AVAILABILITY_FILTER] = preference;
            },
            [SET_INVENTORY_DC_POOL_OVERRIDE](state, dcLocation) {
                state.inventoryDCPoolOverride = dcLocation;
            },
        },

        getters: {
            /* query params we allow to be passed through to our catalog service */
            catalogFilterWhitelist(state) {
                const attributeGroups = get(state, 'contentfulAppConfig.attributeGroups', []);

                return [...CATALOG_QUERY_WHITELIST, ...attributeGroups];
            },
            blockNewSubscriptions(state) {
                return get(state, 'contentfulAppConfig.blockNewSubscriptions', false);
            },
            gridLayout(state) {
                return state.preferences[USER_PREFERENCE_KEY_GRID_LAYOUT];
            },
        },
    });
}
