<template>
    <CoreSlideLayout
        v-bind="$props"
        :slideHeader="SlideHeader"
        :contentfulContentBlock="ContentfulContentBlock"
    >
        <slot></slot>
        <template #logo>
            <slot name="logo"></slot>
        </template>
        <template #progressbar>
            <slot name="progressbar"></slot>
        </template>
    </CoreSlideLayout>
</template>

<script>
import { markRaw, defineAsyncComponent } from 'vue';
import CoreSlideLayout from '~coreModules/core/components/layouts/CoreSlideLayout.vue';

import SlideHeaderV2 from '~coreModules/core/components/ui/SlideHeaderV2.vue';

export default {
    name: 'SlideLayout',
    components: {
        CoreSlideLayout,
    },
    data() {
        // NU_FEATURE: PROSPECT_BOX
        const SlideHeader =
            this.$runtimeConfig.features.prospectBox ? markRaw(SlideHeaderV2) : undefined;

        return {
            SlideHeader,
            ContentfulContentBlock: markRaw(
                defineAsyncComponent(
                    () => import(
                        /* webpackChunkName: "contentful-content-block" */
                        '~modules/contentful/components/ContentfulContentBlock.vue'),
                ),
            ),
        };
    },
};
</script>
