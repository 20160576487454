import { GLOBAL_EVENT } from '~coreModules/core/js/store';
import { getNamespacedMethods } from '~coreModules/core/js/store-utils';

import {
    ACCORDION_OPENED,
    CATALOG_FILTER_UPDATED,
    CATALOG_SORT_UPDATED,
    CHECKOUT_START,
    CONTENTFUL_CONTENT_CLICKED,
    CONTENT_CARD_CLICKED,
    CONTENT_CARD_SCROLL,
    EMAIL_SUBSCRIBE,
    FOOTER_CLICKED,
    FEEDBACK_COMPLETED,
    FEEDBACK_STARTED,
    IN_GRID_CONTENT_CLICKED,
    LOGIN_COMPLETED,
    LOGIN_STARTED,
    LOGOUT,
    MARKETING_SMS_SUBSCRIBED,
    NAVIGATION_CLICKED,
    NOTIFICATION_CLICKED,
    NOTIFICATION_DISMISSED,
    NOTIFICATION_VIEWED,
    OPERATIONAL_SMS_SUBSCRIBED,
    PAGEVIEW,
    PAGINATION_CLICK,
    PRODUCT_CLICK,
    PRODUCT_SAVED_CHANGE,
    PURCHASE_SUCCESS,
    SEARCH,
    SEARCH_STARTED,
    SEARCH_REDIRECT,
    SERVICE_ERROR,
    SIGN_UP_STARTED,
    SIGN_UP_COMPLETED,
    VIEW_ALL_CLICK,
    VIEW_CONTENT_CARD,
    VIEW_PRODUCT,
    VIEW_PRODUCT_LIST,
} from '~coreModules/core/js/global-event-constants';

import {
    ANALYTICS_MODULE_NAME,
    TRIGGER_PAGEVIEW,
    TRACK_ACCORDION_OPENED,
    TRACK_CAROUSEL_SCROLL,
    TRACK_CATALOG_FILTER_UPDATED,
    TRACK_CATALOG_SORT_UPDATED,
    TRACK_CONTENTFUL_CONTENT_CLICKED,
    TRACK_CONTENT_CARD_SCROLL,
    TRACK_CHECKOUT_START,
    TRACK_EMAIL_SUBSCRIBE,
    TRACK_ERROR,
    TRACK_FEEDBACK_COMPLETED,
    TRACK_FEEDBACK_STARTED,
    TRACK_FOOTER_CLICKED,
    TRACK_IN_GRID_CONTENT_CLICKED,
    TRIGGER_LOGIN_COMPLETED,
    TRACK_LOGIN_STARTED,
    TRACK_LOGOUT,
    TRACK_SMS_SUBSCRIBED,
    TRACK_NAVIGATION_CLICKED,
    TRACK_NOTIFICATION_CLICKED,
    TRACK_NOTIFICATION_DISMISSED,
    TRACK_NOTIFICATION_VIEWED,
    TRACK_PAGINATION_CLICK,
    TRACK_PRODUCT_CLICK,
    TRACK_PRODUCT_SAVED_CHANGE,
    TRACK_PURCHASE_SUCCESS,
    TRACK_SEARCH,
    TRACK_SEARCH_STARTED,
    TRACK_SEARCH_REDIRECT,
    TRACK_SIGN_UP_STARTED,
    TRACK_SIGN_UP_COMPLETED,
    TRACK_VIEW_ALL_CLICK,
    TRACK_VIEW_CONTENT_CARD,
    TRACK_VIEW_PRODUCT,
    TRACK_VIEW_PRODUCT_LIST,
    TRACK_CONTENT_CARD_CLICKED,
} from '~coreModules/analytics/js/analytics-store';

export default function analyticsActionListener(store, action) {
    const { dispatch, commit } = getNamespacedMethods(store, ANALYTICS_MODULE_NAME);

    const { payload, type } = action;

    if (type === GLOBAL_EVENT) {
        const eventType = payload.type;
        const eventData = payload.data;

        switch (eventType) {
        case PAGEVIEW:
            dispatch(TRIGGER_PAGEVIEW, eventData);
            break;
        case SERVICE_ERROR:
            if (eventData.errorSlug) {
                dispatch(TRACK_ERROR, eventData.errorSlug);
            }
            break;
        case ACCORDION_OPENED:
            dispatch(TRACK_ACCORDION_OPENED, eventData);
            break;
        case CATALOG_FILTER_UPDATED:
            dispatch(TRACK_CATALOG_FILTER_UPDATED, eventData);
            break;
        case CATALOG_SORT_UPDATED:
            dispatch(TRACK_CATALOG_SORT_UPDATED, eventData);
            break;
        case CHECKOUT_START:
            dispatch(TRACK_CHECKOUT_START, eventData);
            break;
        case CONTENTFUL_CONTENT_CLICKED:
            dispatch(TRACK_CONTENTFUL_CONTENT_CLICKED, eventData);
            break;
        case CONTENT_CARD_CLICKED:
            dispatch(TRACK_CONTENT_CARD_CLICKED, eventData);
            break;
        case CONTENT_CARD_SCROLL:
            dispatch(TRACK_CONTENT_CARD_SCROLL, eventData);
            break;
        case EMAIL_SUBSCRIBE:
            dispatch(TRACK_EMAIL_SUBSCRIBE, eventData);
            break;
        case FEEDBACK_COMPLETED:
            dispatch(TRACK_FEEDBACK_COMPLETED, eventData);
            break;
        case FEEDBACK_STARTED:
            dispatch(TRACK_FEEDBACK_STARTED, eventData);
            break;
        case FOOTER_CLICKED:
            dispatch(TRACK_FOOTER_CLICKED, eventData);
            break;
        case IN_GRID_CONTENT_CLICKED:
            dispatch(TRACK_IN_GRID_CONTENT_CLICKED, eventData);
            break;
        case LOGIN_COMPLETED:
            dispatch(TRIGGER_LOGIN_COMPLETED, eventData);
            break;
        case LOGIN_STARTED:
            dispatch(TRACK_LOGIN_STARTED, eventData);
            break;
        case LOGOUT:
            commit(TRACK_LOGOUT, eventData);
            break;
        case MARKETING_SMS_SUBSCRIBED:
            eventData.action = 'marketing_sms_subscribe';
            dispatch(TRACK_SMS_SUBSCRIBED, eventData);
            break;
        case NAVIGATION_CLICKED:
            dispatch(TRACK_NAVIGATION_CLICKED, eventData);
            break;
        case NOTIFICATION_CLICKED:
            dispatch(TRACK_NOTIFICATION_CLICKED, eventData);
            break;
        case NOTIFICATION_DISMISSED:
            dispatch(TRACK_NOTIFICATION_DISMISSED, eventData);
            break;
        case NOTIFICATION_VIEWED:
            dispatch(TRACK_NOTIFICATION_VIEWED, eventData);
            break;
        case OPERATIONAL_SMS_SUBSCRIBED:
            eventData.action = 'operational_sms_subscribe';
            dispatch(TRACK_SMS_SUBSCRIBED, eventData);
            break;
        case PAGINATION_CLICK:
            dispatch(TRACK_PAGINATION_CLICK, eventData);
            dispatch(TRACK_CAROUSEL_SCROLL, eventData);
            break;
        case PRODUCT_CLICK:
            dispatch(TRACK_PRODUCT_CLICK, eventData);
            break;
        case PRODUCT_SAVED_CHANGE:
            dispatch(TRACK_PRODUCT_SAVED_CHANGE, eventData);
            break;
        case PURCHASE_SUCCESS:
            dispatch(TRACK_PURCHASE_SUCCESS, eventData);
            break;
        case SEARCH:
            dispatch(TRACK_SEARCH, eventData);
            break;
        case SEARCH_REDIRECT:
            dispatch(TRACK_SEARCH_REDIRECT, eventData);
            break;
        case SEARCH_STARTED:
            dispatch(TRACK_SEARCH_STARTED, eventData);
            break;
        case SIGN_UP_STARTED:
            dispatch(TRACK_SIGN_UP_STARTED, eventData);
            break;
        case SIGN_UP_COMPLETED:
            commit(TRACK_SIGN_UP_COMPLETED, {
                profileId: eventData.id,
                traceId: eventData.responseHeaders?.traceId,
                smsOptIn: eventData.communication?.smsOptIn,
            });
            break;
        case VIEW_ALL_CLICK:
            dispatch(TRACK_VIEW_ALL_CLICK, eventData);
            break;
        case VIEW_CONTENT_CARD:
            dispatch(TRACK_VIEW_CONTENT_CARD, eventData);
            break;
        case VIEW_PRODUCT:
            dispatch(TRACK_VIEW_PRODUCT, eventData);
            break;
        case VIEW_PRODUCT_LIST:
            dispatch(TRACK_VIEW_PRODUCT_LIST, eventData);
            break;
        default:
            break;
        }
    }
}
