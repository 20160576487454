import { GLOBAL_EVENT } from '~coreModules/core/js/store';
import { getNamespacedMethods } from '~coreModules/core/js/store-utils';
import {
    RESEND_TOKEN,
    ACCOUNT_CREATED,
} from '~coreModules/core/js/global-event-constants';

import {
    SUBSCRIPTION_CREATE_STARTED,
    SUBSCRIPTION_CREATE_COMPLETED,
    REFERRAL_SENT,
    REFERRAL_CLICKED,
    REFERRAL_LINK_SHARED,
    SUBSCRIPTION_COUPON_REDEEMED,
    SUBSCRIPTION_STATUS_UPDATED,
    BOX_ITEM_STATUS_UPDATED,
    BOX_MINIMUM_ITEMS_NOT_MET,
    SHIPPING_ADDRESS_UPDATED,
    EMAIL_UPDATED,
    BOX_LINK_CLICKED,
    CHAT_CLICK,
    CATALOG_GRID_LAYOUT_UPDATED,
    CATALOG_AVAILABILITY_UPDATED,
    COUPON_CODE_APPLIED,
    BOX_ITEM_OUT_OF_STOCK,
    ITEM_PURCHASE_STARTED,
    ITEM_PURCHASE_COMPLETED,
    PROFILE_UPDATED,
    PRODUCT_TOUR_VIEWED,
    BONUS_ITEM,
    RENEW_EARLY,
    REPRINT_LABEL,
    DELETE_SUBLIST,
    CREATE_SUBLIST,
    REQUEST_BOX,
    REVIEW_PHOTO_CLICK,
    CHECK_CARRIER_STATUS,
    CHECK_CARRIER_SUCCESS,
    CHECK_CARRIER_FAILURE,
    REVIEW_FILTER_UPDATED,
    REVIEW_SORT_UPDATED,
    ITEM_SAVED_CHANGE,
    SHARE_CONTENT,
    VIEW_SIMILAR_CLICK,
    SUBLIST_LINK_SHARED,
} from '~modules/core/js/global-event-constants';

import {
    TRACK_PRODUCT_SAVED_CHANGE,
} from '~coreModules/analytics/js/analytics-store';

import {
    ANALYTICS_MODULE_NAME,
    TRACK_ACCOUNT_CREATED,
    TRACK_SUBSCRIPTION_CREATE_STARTED,
    TRACK_SUBSCRIPTION_CREATE_COMPLETED,
    TRACK_SMS_RESEND,
    TRACK_CLOSET_CHANGE,
    TRACK_REFERRAL_SENT,
    TRACK_REFERRAL_CLICKED,
    TRACK_LINK_SHARED,
    TRACK_SUBSCRIPTION_COUPON_REDEEMED,
    TRACK_SUBSCRIPTION_STATUS_UPDATED,
    TRACK_BOX_ITEM_STATUS_UPDATED,
    TRACK_SHIPPING_ADDRESS_UPDATED,
    TRACK_EMAIL_UPDATED,
    TRACK_CATALOG_GRID_LAYOUT_UPDATED,
    TRACK_CATALOG_AVAILABILITY_UPDATED,
    TRACK_COUPON_CODE_APPLIED,
    TRACK_BOX_ITEM_OUT_OF_STOCK,
    TRACK_BOX_MINIMUM_ITEMS_NOT_MET,
    TRACK_ITEM_PURCHASE,
    TRACK_CHAT_CLICK,
    TRACK_PROFILE_UPDATED,
    TRACK_PRODUCT_TOUR_VIEWED,
    TRACK_BONUS_ITEM,
    TRACK_RENEW_EARLY,
    TRACK_REPRINT_LABEL,
    TRACK_REQUEST_BOX,
    TRACK_REVIEW_PHOTO_CLICK,
    TRACK_BOX_LINK_CLICKED,
    TRACK_DELETE_SUBLIST,
    TRACK_CREATE_SUBLIST,
    TRACK_CHECK_FOR_DROP_OFF,
    TRACK_REVIEW_FILTER_UPDATED,
    TRACK_REVIEW_SORT_UPDATED,
    TRACK_SHARE_CONTENT,
    TRACK_VIEW_SIMILAR_CLICKED,
    TRACK_SUBLIST_LINK_SHARED,
} from '~modules/analytics/js/analytics-store';

export default function analyticsActionListener(store, action) {
    const { dispatch, commit } = getNamespacedMethods(store, ANALYTICS_MODULE_NAME);

    const { payload, type } = action;

    if (type === GLOBAL_EVENT) {
        const eventType = payload.type;
        const eventData = payload.data;

        switch (eventType) {
        case RESEND_TOKEN:
            dispatch(TRACK_SMS_RESEND);
            break;
        case ACCOUNT_CREATED:
            commit(TRACK_ACCOUNT_CREATED, {
                profileId: eventData.id,
                subscriptionStatus: eventData.status.subscriptionStatus,
            });
            break;
        case SUBSCRIPTION_CREATE_STARTED:
            dispatch(TRACK_SUBSCRIPTION_CREATE_STARTED);
            break;
        case SUBSCRIPTION_CREATE_COMPLETED:
            commit(TRACK_SUBSCRIPTION_CREATE_COMPLETED, eventData);
            break;
        case REFERRAL_SENT:
            dispatch(TRACK_REFERRAL_SENT, eventData);
            break;
        case REFERRAL_CLICKED:
            dispatch(TRACK_REFERRAL_CLICKED, eventData.type);
            break;
        case REFERRAL_LINK_SHARED:
            dispatch(TRACK_LINK_SHARED, eventData);
            break;
        case SUBSCRIPTION_COUPON_REDEEMED:
            dispatch(TRACK_SUBSCRIPTION_COUPON_REDEEMED, eventData);
            break;
        // NU_TODO: https://urbnit.atlassian.net/browse/TYP-23556
        // remove ITEM_SAVED_CHANGE when we deprecate UA
        // update references of ITEM_SAVED_CHANGE to PRODUCT_SAVED_CHANGE
        case ITEM_SAVED_CHANGE: {
            const { UAClosetProductAnalyticsData, ...productAnalyticsData } = eventData;

            if (UAClosetProductAnalyticsData) {
                dispatch(TRACK_CLOSET_CHANGE, UAClosetProductAnalyticsData);
            }

            dispatch(TRACK_PRODUCT_SAVED_CHANGE, productAnalyticsData);
            break;
        }
        case SUBSCRIPTION_STATUS_UPDATED:
            dispatch(TRACK_SUBSCRIPTION_STATUS_UPDATED, eventData);
            break;
        case BOX_ITEM_STATUS_UPDATED:
            dispatch(TRACK_BOX_ITEM_STATUS_UPDATED, eventData);
            break;
        case SHIPPING_ADDRESS_UPDATED:
            dispatch(TRACK_SHIPPING_ADDRESS_UPDATED);
            break;
        case EMAIL_UPDATED:
            dispatch(TRACK_EMAIL_UPDATED);
            break;
        case BOX_LINK_CLICKED:
            dispatch(TRACK_BOX_LINK_CLICKED, eventData);
            break;
        case CHAT_CLICK:
            dispatch(TRACK_CHAT_CLICK, eventData);
            break;
        case CATALOG_GRID_LAYOUT_UPDATED:
            dispatch(TRACK_CATALOG_GRID_LAYOUT_UPDATED, eventData);
            break;
        case CATALOG_AVAILABILITY_UPDATED:
            dispatch(TRACK_CATALOG_AVAILABILITY_UPDATED, eventData);
            break;
        case COUPON_CODE_APPLIED:
            dispatch(TRACK_COUPON_CODE_APPLIED, eventData);
            break;
        case BOX_ITEM_OUT_OF_STOCK:
            dispatch(TRACK_BOX_ITEM_OUT_OF_STOCK, eventData);
            break;
        case BOX_MINIMUM_ITEMS_NOT_MET:
            dispatch(TRACK_BOX_MINIMUM_ITEMS_NOT_MET, eventData);
            break;
        case ITEM_PURCHASE_STARTED:
            eventData.action = 'item-purchase-started';
            dispatch(TRACK_ITEM_PURCHASE, eventData);
            break;
        case ITEM_PURCHASE_COMPLETED:
            eventData.action = 'item-purchase-completed';
            dispatch(TRACK_ITEM_PURCHASE, eventData);
            break;
        case PROFILE_UPDATED:
            dispatch(TRACK_PROFILE_UPDATED);
            break;
        case PRODUCT_TOUR_VIEWED:
            dispatch(TRACK_PRODUCT_TOUR_VIEWED, eventData);
            break;
        case CHECK_CARRIER_STATUS:
            dispatch(TRACK_CHECK_FOR_DROP_OFF, eventData);
            break;
        case CHECK_CARRIER_SUCCESS:
            dispatch(TRACK_CHECK_FOR_DROP_OFF, eventData);
            break;
        case CHECK_CARRIER_FAILURE:
            dispatch(TRACK_CHECK_FOR_DROP_OFF, eventData);
            break;
        case BONUS_ITEM:
            dispatch(TRACK_BONUS_ITEM, eventData);
            break;
        case RENEW_EARLY:
            dispatch(TRACK_RENEW_EARLY, eventData);
            break;
        case REPRINT_LABEL:
            dispatch(TRACK_REPRINT_LABEL, eventData);
            break;
        case DELETE_SUBLIST:
            dispatch(TRACK_DELETE_SUBLIST);
            break;
        case CREATE_SUBLIST:
            dispatch(TRACK_CREATE_SUBLIST);
            break;
        case REQUEST_BOX:
            dispatch(TRACK_REQUEST_BOX, eventData);
            break;
        case REVIEW_PHOTO_CLICK:
            dispatch(TRACK_REVIEW_PHOTO_CLICK, eventData);
            break;
        case REVIEW_FILTER_UPDATED:
            dispatch(TRACK_REVIEW_FILTER_UPDATED, eventData);
            break;
        case REVIEW_SORT_UPDATED:
            dispatch(TRACK_REVIEW_SORT_UPDATED, eventData);
            break;
        case SHARE_CONTENT:
            dispatch(TRACK_SHARE_CONTENT, eventData);
            break;
        case VIEW_SIMILAR_CLICK:
            dispatch(TRACK_VIEW_SIMILAR_CLICKED, eventData);
            break;
        case SUBLIST_LINK_SHARED:
            dispatch(TRACK_SUBLIST_LINK_SHARED, eventData);
            break;
        default:
            break;
        }
    }
}
