<script>

import { mapActions, mapGetters, mapState } from 'vuex';
import { get } from 'lodash-es';

import { boxInventoryUrl, prospectBoxInventoryUrl } from '~modules/box/js/box-utils';

import { BOX_MODULE_NAME, CHECK_BOX_ITEM_QUANTITY } from '~modules/box/js/box-store';
import { PROFILE_MODULE_NAME } from '~modules/profile/js/profile-store';

export default {
    name: 'WebSocketConnector',
    data() {
        return {
            socketConnection: null,
            keepAliveTimer: null,
        };
    },
    computed: {
        ...mapState(BOX_MODULE_NAME, {
            boxId: state => get(state, 'openBox.id', ''),
        }),
        ...mapGetters(BOX_MODULE_NAME, [
            'boxIsOpen',
        ]),
        ...mapGetters(PROFILE_MODULE_NAME, [
            'isProspectiveUserBlockedFromSubscribing',
            'isProspectiveUser',
        ]),
        inventoryUrl() {
            return this.isProspectiveUser ? prospectBoxInventoryUrl(this.boxId) :
                boxInventoryUrl(this.boxId);
        },
    },
    watch: {
        async isProspectiveUser(isProspectiveUser, wasProspectiveUser) {
            if (wasProspectiveUser && !isProspectiveUser && !!this.socketConnection) {
                this.disconnectWebsocket();
                // 2 second delay to account for the prospect box transferring to box
                await new Promise(resolver => setTimeout(resolver, 2000));
                this.connectWebSocketIfBoxIsOpen();
            }
        },
        boxIsOpen(isOpen) {
            if (isOpen === false && !!this.socketConnection) {
                this.disconnectWebsocket();
            } else if (isOpen) {
                this.connectWebSocketIfBoxIsOpen();
            }
        },
    },
    mounted() {
        this.connectWebSocketIfBoxIsOpen();
    },
    unmounted() {
        this.disconnectWebsocket();
    },
    methods: {
        ...mapActions(BOX_MODULE_NAME, {
            checkBoxItemQuantity: CHECK_BOX_ITEM_QUANTITY,
        }),
        connectWebSocketIfBoxIsOpen() {
            if (
                !this.isProspectiveUserBlockedFromSubscribing &&
                !this.socketConnection &&
                this.boxIsOpen &&
                this.boxId
            ) {
                this.connectWebsocket();
            }
        },
        connectWebsocket() {
            try {
                this.socketConnection = new WebSocket(this.inventoryUrl);

                this.socketConnection.onopen = (event) => {
                    this.$logger.debug('\n\n websocket connected ', new Date(), event, '\n\n');
                    this.keepAlive();
                };
                this.socketConnection.onclose = (event) => {
                    this.$logger.debug('\n\n\n\n websocket closed ', new Date(), event, '\n\n\n\n');
                    this.cancelKeepAlive();
                };
                this.socketConnection.onerror = (event) => {
                    this.$logger.debug('\n\n websocket error ', event, '\n\n');
                };
                this.socketConnection.onmessage = (message) => {
                    this.$logger.debug('\n\n websocket message: ', message, '\n\n');
                    const { inventoryBySku: skusToUpdate } = JSON.parse(message.data);

                    if (skusToUpdate) {
                        this.checkBoxItemQuantity(skusToUpdate);
                    }
                };
            } catch (error) {
                this.$logger.debug('\n\n error connecting to websocket ', error, '\n\n');
                this.socketConnection = null;
            }
        },
        keepAlive() {
            if (this.socketConnection.readyState === this.socketConnection.OPEN) {
                this.socketConnection.send('');
                this.$logger.debug('\n\n sending websocket heartbeat \n\n');
            }

            this.keepAliveTimer = setTimeout(this.keepAlive, 20000);
        },
        cancelKeepAlive() {
            if (this.keepAliveTimer) {
                clearTimeout(this.keepAliveTimer);
            }
        },
        disconnectWebsocket() {
            if (this.socketConnection) {
                this.socketConnection.close();
                this.socketConnection = null;
                this.$logger.debug('-- disconnected websocket --');
            }
        },
    },
    render() {
        return null;
    },
};

</script>

<style lang="scss">

</style>
