export const REVIEW_CTA_ACTIONS = {
    proceed: 'proceed',
    previous: 'previous',
    exit: 'exit',
    submitAndProceed: 'submitAndProceed',
    destination: 'destination',
    requestPause: 'requestPause',
    requestCancel: 'requestCancel',
    resumeFromPause: 'resumeFromPause',
    extendPause: 'extendPause',
    refundEligible: 'checkRefundEligibility',
    issueRefund: 'issueRefund',
    checkForDropOff: 'checkForDropOff',
};

export const ASYNC_REVIEW_CTA_ACTIONS = [
    REVIEW_CTA_ACTIONS.submitAndProceed,
    REVIEW_CTA_ACTIONS.requestPause,
    REVIEW_CTA_ACTIONS.requestCancel,
    REVIEW_CTA_ACTIONS.resumeFromPause,
    REVIEW_CTA_ACTIONS.extendPause,
    REVIEW_CTA_ACTIONS.refundEligible,
    REVIEW_CTA_ACTIONS.issueRefund,
    REVIEW_CTA_ACTIONS.checkForDropOff,
];

export const CTA_ACTION_DEFAULT_STYLES = {
    primary: 'primary-dark',
    secondary: 'secondary-dark',
    secondaryStacked: 'link-dark',
};

export const REVIEW_SCHEME_LAYOUTS = {
    oneCol: '1-column',
    twoCol: '2-column',
};

export const REVIEW_SCHEME_TYPES = {
    general: 'GENERAL',
    thriftInquiry: 'THRIFT_INQUIRY',
    sku: 'SKU',
};

export const REVIEW_QUESTION_IDS = {
    checkForDropOff: 'check-for-drop-off-service-call',
    refundEligible: 'refund-eligible',
};

export const REVIEW_SECONDARY_BUTTON_TYPES = {
    cancel: 'cancel',
    previous: 'previous',
};

export const REVIEW_SCHEME_CTA_POSITIONS = {
    inlineStacked: 'inline-stacked',
    inlineHorizontal: 'inline-horizontal',
    fixedToBottom: 'fixed-to-bottom',
};

export const QUESTION_SET_TYPES = {
    CONFIRMATION: 'confirmation',
    DYNAMIC: 'dynamicQuestionSet',
    INFO_SLIDE: 'infoSlide',
    QUESTION_SET: 'questionSet',
};

export const QUESTION_CONTENT_TYPES = {
    UNREVIEWED_ITEMS: 'unreviewedItems',
};

export const REVIEW_QUESTION_TYPES = {
    address: 'address',
    checkbox: 'checkbox',
    dropdown: 'drop-down',
    freeformLong: 'freeform-long',
    freeformLongCSContact: 'freeform-long-cs-contact',
    freeformShort: 'freeform-short',
    image: 'image',
    itemSelect: 'item-select',
    menu: 'menu',
    radio: 'radio',
    rating: 'rating',
    satisfactionScore: 'satisfaction-score',
    sliderSet: 'sliderSet',
};

export const INTERCOM_CASE_TRIGGER_TYPES = {
    WITH_CHAT: 'chat-with-display',
    WITHOUT_CHAT: 'chat-no-display',
};
