import axios from 'axios';
import { get, isUndefined } from 'lodash-es';

import {
    pluckDataInterceptor,
    responseLoggerInterceptor,
    responseErrorLoggerInterceptor,
    requestLoggerInterceptor,
} from '~coreServer/server-interceptors';

import { NUU_COOKIE_TRACKING } from '~coreModules/core/js/cookie-constants';
import {
    NUU_HEADER_CLIENT_ID,
    NUU_HEADER_CHANNEL,
    URBN_HEADER_CLIENT_ID,
    NUU_HEADER_TRACKING_OPT_IN,
} from '~coreModules/core/js/constants';
import { NUU_COOKIE_CLIENT_ID } from '~coreModules/core/js/es5/constants';

import { GLOBAL_EVENT } from '~coreModules/core/js/store';
import { SERVICE_ERROR } from '~coreModules/core/js/global-event-constants';

import {
    addAuthCookie,
    handleR15TokenErrorInterceptor,
    responseNuulyHeaderInterceptor,
    setR15RequestHeaders,
    setLocationIdHeaderInterceptor,
} from '~coreModules/core/js/utility-interceptors';

import { paramsSerializer } from '~coreModules/core/js/url-utils';

export default function createRestClient({
    store,
    request,
    serverBaseApiUrl,
    clientBaseApiUrl,
    logger,
    channel,
    authCookieName,
    cookieInterface,
}) {
    const baseURL = process.env.VUE_ENV === 'server' ? serverBaseApiUrl : clientBaseApiUrl;
    const trackingCookie = cookieInterface.get(NUU_COOKIE_TRACKING);

    const headers = {
        [NUU_HEADER_CHANNEL]: channel,
        [NUU_HEADER_TRACKING_OPT_IN]: !isUndefined(trackingCookie) ? trackingCookie : true,
        // Adds device specific identifier as a header to all requests
        [NUU_HEADER_CLIENT_ID]: cookieInterface.get(NUU_COOKIE_CLIENT_ID),
        [URBN_HEADER_CLIENT_ID]: cookieInterface.get(NUU_COOKIE_CLIENT_ID),
    };

    // force credentials to be sent across domains, we enable this so that our crendentials are sent along
    // with requests to our GRPC rest endpoints which are hosted on a different domain than this application
    const withCredentials = true;

    const restClient = axios.create({ baseURL, paramsSerializer, headers, withCredentials });

    // Axios request interceptors run right-to-left, so list them the reverse of the expected order
    restClient.interceptors.request.use(requestLoggerInterceptor.bind(request, logger));

    // Add r15 request headers
    restClient.interceptors.request.use(
        req => setR15RequestHeaders(req, store));

    // Add auth cookie on SSR calls
    restClient.interceptors.request.use(addAuthCookie.bind(null, request, logger, authCookieName));

    // Response interceptors run left-to-right, so put in expected order
    restClient.interceptors.response.use(res => responseLoggerInterceptor(logger, res),
        (error) => {
            const err = get(error.response, 'data');

            if (err) {
                store.dispatch(GLOBAL_EVENT, {
                    type: SERVICE_ERROR,
                    data: err,
                });
            }
            return responseErrorLoggerInterceptor(logger, error);
        },
    );

    restClient.interceptors.response.use(res => responseNuulyHeaderInterceptor(res));
    restClient.interceptors.response.use(res => setLocationIdHeaderInterceptor(res, store));

    restClient.interceptors.response.use(pluckDataInterceptor);

    restClient.interceptors.response.use(
        res => res,
        error => handleR15TokenErrorInterceptor(error, store),
    );

    return restClient;
}
