export const NAV_TYPE_CODES = {
    BROWSE: 'DEFAULT',
    DISCOVER: 'GATEWAY',
    EXTERNAL: 'EXTERNAL',
    HEADING: 'HEADING',
    INFO: 'CONTENT',
    ROOT: 'ROOT',
    SMART: 'SMART',
};

export const NAV_SLUG_MAPPING = {
    DEFAULT: 'browse',
    SMART: 'browse',
};

export const NON_PAGE_NAV_TYPE_CODES = [
    NAV_TYPE_CODES.ROOT,
    NAV_TYPE_CODES.HEADING,
    NAV_TYPE_CODES.EXTERNAL,
];

export const ACCOUNT_SUB_NAV_TYPES = {
    TABBED: 'TABBED',
    LIST: 'LIST',
    DROPDOWN: 'DROPDOWN',
};

export const HEADER_NAV_ROOT_SLUG = 'shopping-root';
export const FOOTER_NAV_ROOT_SLUG = 'footer-root';
export const HELP_NAV_ROOT_SLUG = 'help-root';
export const FOOTER_NAV_SOCIAL_LINKS_SLUG = 'footer-social-links';
export const FOOTER_NAV_LEGAL_LINKS_SLUG = 'footer-legal-links';
export const FOOTER_NAV_LEGAL_LINKS_SLUG_CA = 'footer-legal-links-ca';
export const FOOTER_NAV_COLUMN_PREFIX = 'footer-column-';
