import { createRouter, createMemoryHistory, createWebHistory } from 'vue-router';
import { get, isEqual } from 'lodash-es';

import { ClientsideNavigationUncaughtError, ClientsideNavigationChunkLoadError } from '~coreModules/core/js/es5/errors';
import { genericRouteNames } from '~coreModules/core/js/router-constants';
import {
    generateUrl,
    pathHasTrailingSlash,
    onlyHashHasChanged,
    modalHashEventHasClearedQuery,
} from '~coreModules/core/js/url-utils';

import { getModalHashEventWithProps } from '~coreModules/modals/js/modal-utils';
import { getNuulySiteType } from '~coreModules/core/js/router-utils';

import { MODALS_MODULE_NAME, HANDLE_MODAL_HASH_EVENT } from '~coreModules/modals/js/modals-store';
import { SET_SITE_BUSINESS_TYPE, SET_ROUTE_IS_AUTO_SCROLLING, CANCEL_NAVIGATION } from '~coreModules/core/js/store';

// Expose a factory function to ensure a new router per request
export default function createBaseRouter(store, appRouterConfig) {
    const isServer = process.env.VUE_ENV === 'server';

    const router = createRouter({
        history: isServer ? createMemoryHistory() : createWebHistory(),
        scrollBehavior(to, from, savedPosition) {
            const fromPath = get(from, 'path');
            const toPath = get(to, 'path');
            const toHash = get(to, 'hash');
            const pathIsUnchanged = isEqual(toPath, fromPath);

            if ('scrollRestoration' in get(window, 'history', false)) {
                window.history.scrollRestoration = 'manual';
            }

            // Maintain users scroll position when navigating via browser forward/back buttons
            if (savedPosition) {
                return new Promise((resolve) => {
                    store.commit(`${SET_ROUTE_IS_AUTO_SCROLLING}`, true);

                    setTimeout(() => {
                        resolve(savedPosition);
                    }, 300);
                }).finally(() => {
                    // the next line, also used below in the default return, ensures that the commit runs after
                    // the consumer of the Promise by placing it on the top of the call stack, i.e. ensures that the
                    // scrolling operation has actually completed before calling the mutator
                    setTimeout(store.commit(`${SET_ROUTE_IS_AUTO_SCROLLING}`, false), 0);
                });
            }

            // Scroll to an anchor if present
            if (toHash) {
                return Promise.resolve({
                    selector: toHash,
                });
            }

            // If only the query has changed, dont do anything
            if (pathIsUnchanged) {
                return Promise.resolve({});
            }

            // Otherwise, scroll to top of the page
            return new Promise((resolve) => {
                store.commit(`${SET_ROUTE_IS_AUTO_SCROLLING}`, true);

                setTimeout(() => {
                    resolve({ top: 0, left: 0 });
                }, 250);
            }).finally(() => {
                setTimeout(() => store.commit(`${SET_ROUTE_IS_AUTO_SCROLLING}`, false), 0);
            });
        },
        routes: [...appRouterConfig.routes],
    });

    // Only need this client side as SSR errors don't ever do next(false) to
    // prevent routing
    if (process.env.VUE_ENV === 'client') {
        router.beforeEach((to, from, next) => {
            const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
            const { loggedIn, phoneLoginRedirectPath } = store.getters;

            if (requiresAuth && !loggedIn) {
                next(phoneLoginRedirectPath);
            }

            // if a modalHashEvent is found
            // and either only the hash changed,
            // or the hash changed and the query was inadvertently removed
            // launch the modal without adding the hash to the url
            const modalHashEvent = getModalHashEventWithProps(to, appRouterConfig.runtimeConfig.features);

            if (modalHashEvent &&
                (onlyHashHasChanged(to, from) || modalHashEventHasClearedQuery(modalHashEvent, to, from))) {
                store.dispatch(`${MODALS_MODULE_NAME}/${HANDLE_MODAL_HASH_EVENT}`, modalHashEvent);
                router.replace(from);
                return;
            }

            if (pathHasTrailingSlash(to.path)) {
                const newPath = generateUrl(to.path, to.query, to.hash);
                next(newPath);
            }

            store.commit(SET_SITE_BUSINESS_TYPE, getNuulySiteType(to.href));
            next();
        });

        router.onError((error) => {
            setTimeout(() => {
                try {
                    if (error?.name === 'ChunkLoadError') {
                        store.commit(CANCEL_NAVIGATION);
                        throw new ClientsideNavigationChunkLoadError(error);
                    } else {
                        router.push({ name: genericRouteNames.error });
                        throw new ClientsideNavigationUncaughtError(error);
                    }
                } catch (err) {
                    store.$logger.error(err);
                    window?.newrelic?.noticeError?.(err);
                }
            }, 0);
        });
    } else {
        router.beforeEach((to, from, next) => {
            store.commit(SET_SITE_BUSINESS_TYPE, getNuulySiteType(to.href));
            next();
        });
    }

    return router;
}
