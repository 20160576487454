export const REVIEW_SCHEMES = {
    CANCEL: 'request-cancel',
    EXTEND_PAUSE: 'extend-pause',
    PAUSE: 'request-pause',
    PRODUCT: 'product',
    RESUME_FROM_PAUSE: 'resume-from-pause',
    REFUND_ELIGIBLE: 'refund-eligible',
};

export const REVIEW_WAITLIST_CONFIRMATION_SLUG = 'waitlist-confirmation';
