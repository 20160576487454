<script>
import {
    mapActions,
    mapGetters,
} from 'vuex';

import { NOTIFICATION_VIEWED } from '~coreModules/core/js/global-event-constants';
import { NOTIFICATION_TYPES } from '~coreModules/analytics/js/analytics-constants';

import {
    ANONYMOUS_NOTIFICATIONS_MODULE_NAME,
    GET_ACTIVE_NOTIFICATION,
    SET_NOTIFICATION_VIEWED,
} from '~coreModules/notifications/js/anonymous-notifications-store';
import { MODALS_MODULE_NAME, LAUNCH_MODAL } from '~coreModules/modals/js/modals-store';
import { GLOBAL_EVENT } from '~coreModules/core/js/store';

import { isNotificationPageBlocked } from '~coreModules/notifications/js/anonymous-notifications-utils';

export default {
    name: 'AnonymousNotificationManager',
    props: {
        allowNotifications: {
            type: Boolean,
            default: false,
        },
        modalHashEvents: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            showNotificationTimeout: null,
        };
    },
    computed: {
        ...mapGetters(ANONYMOUS_NOTIFICATIONS_MODULE_NAME, [
            'activeNotification',
        ]),
        ...mapGetters(MODALS_MODULE_NAME, [
            'activeModalId',
        ]),
    },
    watch: {
        '$route.href': {
            handler(routeHref) {
                if (this.activeNotification && isNotificationPageBlocked(routeHref, this.activeNotification)) {
                    this.stopNotification(this.activeNotification);
                }
            },
        },
        activeModalId(newModalId) {
            if (!!newModalId && newModalId === this.activeNotification?.modalId) {
                this.handleNotificationViewed(this.activeNotification);
            }
        },
        activeNotification: {
            handler(newNotification, oldNotification) {
                if (newNotification && oldNotification !== newNotification) {
                    const { delay } = newNotification;

                    this.showNotificationTimeout = setTimeout(() => {
                        this.showNotification(newNotification);
                    }, delay);
                }
            },
        },
    },
    mounted() {
        this.getActiveNotification(this.$route.href);
    },
    methods: {
        ...mapActions(ANONYMOUS_NOTIFICATIONS_MODULE_NAME, {
            getActiveNotification: GET_ACTIVE_NOTIFICATION,
            setNotificationViewed: SET_NOTIFICATION_VIEWED,
        }),
        ...mapActions(MODALS_MODULE_NAME, {
            launchModal: LAUNCH_MODAL,
        }),
        ...mapActions({
            trackGlobalEvent: GLOBAL_EVENT,
        }),
        stopNotification(notification) {
            clearTimeout(this.showNotificationTimeout);
            this.handleNotificationViewed(notification, false);
        },
        showNotification(notification) {
            const { modalId } = notification;
            if (!this.allowNotifications) {
                this.$logger.debugError(`attempted to launch a modal via nuuly notifications, but the user
                    is not eligible to view notifications, cancelling modal launch for slug: ${modalId}`);
                this.handleNotificationViewed(notification, false);
                return;
            }

            const modalHashEvent = this.modalHashEvents[`#${modalId}`];

            if (modalHashEvent) {
                if (this.activeModalId) {
                    this.$logger.debugError(`attempted to launch a modal via nuuly notifications,
                        but the user was already viewing the ${this.activeModalId} modal,
                        cancelling modal launch`);
                    this.handleNotificationViewed(notification, false);
                } else {
                    this.launchModal(modalHashEvent)
                        .catch((error) => {
                            this.$logger.debugError('launch of nuuly modal failed,\n', error);
                            this.handleNotificationViewed(notification, false);
                        });
                }
            } else {
                this.$logger.debugError(`attempted to launch a modal via nuuly notifications, but no
                    modalHashEvent was found for slug: ${modalId}`);
                this.handleNotificationViewed(notification, false);
            }
        },
        handleNotificationViewed(notification, shouldTrackView = true) {
            const { notificationId, daysToExpiration } = notification;

            if (shouldTrackView) {
                // UA
                this.trackGlobalEvent({
                    type: NOTIFICATION_VIEWED,
                    data: {
                        label: notificationId,
                    },
                });
                // GA4
                this.trackGlobalEvent({
                    type: NOTIFICATION_VIEWED,
                    data: {
                        isGA4: true,
                        notificationType: NOTIFICATION_TYPES.modal,
                        notificationSlug: notificationId,
                    },
                });
            }

            this.setNotificationViewed({ notificationId, daysToExpiration, shouldTrackView });
        },
    },
    render() {
        return null;
    },
};
</script>
