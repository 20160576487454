import { get } from 'lodash-es';

import { getNavigation } from '~coreModules/nav/js/nav-api';
import { buildNavigationTreeForSlug } from '~coreModules/nav/js/nav-utils';
import { getContentfulPageContentForSlug } from '~coreModules/contentful/js/contentful-client';

import {
    HELP_NAV_ROOT_SLUG,
    FOOTER_NAV_COLUMN_PREFIX,
    FOOTER_NAV_ROOT_SLUG,
    FOOTER_NAV_SOCIAL_LINKS_SLUG,
} from '~coreModules/nav/js/nav-constants';
import {
    CONTENT_TYPES,
    CONTENT_SLUGS,
} from '~modules/contentful/js/contentful-constants';
import { COMPLIANCE_MODULE_NAME } from '~coreModules/compliance/js/compliance-store';
import { SITE_SETTINGS_MODULE_NAME } from '~coreModules/site-settings/js/site-settings-store';

export const NAVIGATION_MODULE_NAME = 'navigation';

export const GET_SITE_NAVIGATION = 'GET_SITE_NAVIGATION';
export const SET_SITE_NAVIGATION = 'SET_SITE_NAVIGATION';
export const FETCH_SITE_NAVIGATION = 'FETCH_SITE_NAVIGATION';
export const SET_NAVIGATION_CONTENT = 'SET_NAVIGATION_CONTENT';

export default function createNavigation(logger, config) {
    return {
        namespaced: true,

        state: {
            flatNavigation: {},
            navigationContent: [],
        },

        /* eslint-disable no-param-reassign */
        mutations: {
            [SET_SITE_NAVIGATION](state, { navigationItems }) {
                state.flatNavigation = navigationItems;
            },
            [SET_NAVIGATION_CONTENT](state, { navigationContent }) {
                state.navigationContent = navigationContent;
            },
        },

        /* eslint-enable no-param-reassign */
        actions: {
            async [GET_SITE_NAVIGATION]({ commit }) {
                return getNavigation(this.$r15Svc, config)
                    .then(response => commit(SET_SITE_NAVIGATION, response?.navigation ?? response))
                    .catch((error) => {
                        logger.debugError('Failed to fetch the site navigation: ', error);
                        return Promise.reject(error);
                    });
            },
            async [FETCH_SITE_NAVIGATION]({ commit }) {
                try {
                    const res = await getContentfulPageContentForSlug({
                        contentfulSvc: this.$contentfulSvc,
                        contentType: CONTENT_TYPES.NAV_CONTENT_CONTAINER,
                        slug: CONTENT_SLUGS.SHOPPING,
                        isTimed: false,
                        queryParams: {
                            'fields.channel': 'web',
                        },
                    });

                    commit(SET_NAVIGATION_CONTENT, res);
                } catch (error) {
                    const errorMsg = 'Failed to fetch nav content container';
                    logger.debugError(`${errorMsg}, `, error);
                }
            },
        },

        getters: {
            /* hierarchical footer navigation items */
            footerLinks: (state, getters, rootState, rootGetters) => (footerNav) => {
                const footerLegalLinksSlug = rootGetters[`${COMPLIANCE_MODULE_NAME}/footerLegalLinksSlug`];

                return {
                    legalLinks: footerNav.find(navLink => navLink.slug === footerLegalLinksSlug) || {},
                    socialLinks: footerNav.find(navLink => navLink.slug === FOOTER_NAV_SOCIAL_LINKS_SLUG) || {},
                };
            },
            /* hierarchical footer navigation items */
            getFooterNav: (state, getters) => (appBaseUrl) => {
                const { flatNavigation } = state;

                const footerNav = buildNavigationTreeForSlug({
                    slug: FOOTER_NAV_ROOT_SLUG,
                    flatNavigation,
                    logger,
                    appBaseUrl,
                });

                if (!footerNav) return {};

                const supportLinks = footerNav
                    .filter(navLink => navLink.slug.includes(FOOTER_NAV_COLUMN_PREFIX))
                    .sort((a, b) => a.slug.split(
                        FOOTER_NAV_COLUMN_PREFIX)[1] - b.slug.split(FOOTER_NAV_COLUMN_PREFIX)[1]);

                return { supportLinks, ...getters.footerLinks(footerNav) };
            },
            footerNav(state, getters, rootState, rootGetters) {
                const { appBaseUrl } = rootGetters;

                return getters.getFooterNav(appBaseUrl);
            },
            /* flat help route navigation items */
            helpNav(state, getters, rootState, rootGetters) {
                const { flatNavigation } = state;
                const { appBaseUrl } = rootGetters;

                return buildNavigationTreeForSlug({
                    slug: HELP_NAV_ROOT_SLUG,
                    flatNavigation,
                    logger,
                    appBaseUrl,
                });
            },
            /* contentful-driven link to be displayed for unauthenticated users in the header */
            anonymousHeaderCTA(state, getters, rootState) {
                return get(rootState[SITE_SETTINGS_MODULE_NAME], 'contentfulAppConfig.anonymousHeaderCTA', {});
            },
            /* Featured categories to show at the top of the navigation menu */
            featuredNavigationContent(state, getters, rootState) {
                return get(rootState[SITE_SETTINGS_MODULE_NAME], 'contentfulAppConfig.headerContent', []);
            },
            /* Contentful-driven links to be displayed for unauthenticated users in the menu */
            topLinks(state, getters, rootState) {
                return rootState[SITE_SETTINGS_MODULE_NAME]?.contentfulAppConfig.anonymousTopLinks || [];
            },
            anonymousTopLinks(state, getters) {
                return getters.topLinks
                    .filter(topLink => !topLink.linkPath.includes('login'))
                    .map(topLink => ({
                        slug: topLink.linkPath,
                        displayName: topLink.linkTitle,
                        target: topLink.openInNewWindow ? '_blank' : null,
                    }));
            },
        },
    };
}
