<i18n>
[
    "header__search--label",
]
</i18n>

<template>
    <BaseSearchForm
        ref="searchForm"
        v-model="searchTerms"
        :placeholder="$t('header__search--label')"
        class="c-search-bar"
        @submit="submitSearch"
    />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { MODALS_MODULE_NAME, POP_MODALS } from '~coreModules/modals/js/modals-store';

import { SEARCH_STARTED } from '~coreModules/core/js/global-event-constants';
import { GLOBAL_EVENT } from '~coreModules/core/js/store';
import { rentalRouteNames } from '~modules/core/js/router-constants';

import BaseSearchForm from '~coreModules/core/components/ui/BaseSearchForm.vue';

export default {
    name: 'SearchBar',
    components: {
        BaseSearchForm,
    },
    data() {
        return {
            searchTerms: '',
        };
    },
    computed: {
        ...mapGetters(MODALS_MODULE_NAME, {
            modalIsOpen: 'isOpen',
        }),
    },
    methods: {
        ...mapActions({
            trackGlobalEvent: GLOBAL_EVENT,
        }),
        ...mapActions(MODALS_MODULE_NAME, {
            popModals: POP_MODALS,
        }),
        submitSearch() {
            if (this.searchTerms !== '') {

                this.trackGlobalEvent({
                    type: SEARCH_STARTED,
                    data: {
                        label: this.searchTerms,
                    },
                });

                this.$router.push({
                    name: rentalRouteNames.search,
                    query: {
                        q: this.searchTerms,
                    },
                });

                this.$refs.searchForm.clearSearch(true);

                if (this.modalIsOpen) {
                    this.popModals();
                }
            }
        },
    },
};
</script>

<style lang="scss">
    .c-search-bar {
        max-width: 270px;
    }
</style>
