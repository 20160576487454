import { apiUrls } from '~config/config';

/**
 * Gets users rewards history
 */
export function getRewardsHistory(r15Svc) {
    return r15Svc.get(`${apiUrls.profileService}/rewards/history`);
}

/**
 * Gets a user's personal friendbuy referral link
 */
export function getPersonalReferralLink(r15Svc) {
    return r15Svc.get(`${apiUrls.profileService}/referral/personalReferralLink`);
}

/**
 * Gets users credit balance
 */
export function getCreditBalance(r15Svc) {
    return r15Svc.get(`${apiUrls.profileService}/subscriptions/balance`);
}

/**
 * Updates the shipping address saved for a users account
 * @param {Object} shippingAddress - r15 formatted shipping address
 */
export function updateShippingAddress(r15Svc, shippingAddress) {
    return r15Svc.put(`${apiUrls.subscriptionService}/shipping/address`, shippingAddress);
}

/**
 * update billingToken to profile
 * @param {String} billingToken - billingToken to profile
 * @returns {Promise}
 */
export function updateBillingToken(r15Svc, billingToken) {
    return r15Svc.put(`${apiUrls.profileService}/subscriptions/billingToken`, { billingToken });
}

/**
 * Exchanges an employeeID for a discount code
 * @param {String} employeeId
 * @return {Promise} - service request
 */
export function generateEmployeeDiscountCode(r15Svc, employeeId) {
    return r15Svc.post(`${apiUrls.profileService}/employees/${employeeId}/discount`);
}

export function getIntercomChatToken(r15Svc) {
    return r15Svc.get(`${apiUrls.chatService}/token?channel=web`);
}
